@import "./cf-variables";

// boilerplate
.conversational-form {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	// if you want to hide the Conversational Form, use display none.
	visibility: visible !important;
	*, *:before, *:after {
		box-sizing: border-box;
		outline: none;
	}

	&:before {
		content: "";
		width: 100%;
		height: 50px;
		pointer-events: none;
		background: linear-gradient(to bottom, rgba($cf-color-background,1) 0%, rgba($cf-color-background,0) 100%);
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 2;
	}

	button {
		border: none;
		background: none;
		margin: 0px;
		padding: 0px;
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	}

	menu {
		padding: 0px;
		margin: 0px;
	}

	ul, ol, li {
		margin: 0;
		padding: 0;
	}

	a, a:active, a:visited {
		color: currentColor;
	}

	button, a {
		cursor: pointer;
	}
	
	font-family: $cf-font-family;
	font-size: $cf-chat-response-font-size;
	line-height: $cf-chat-response-font-size + 4;

	@media (max-width: $cf-breakpoint-medium) {
		font-size: $cf-mobile-font-size;
		line-height: $cf-mobile-font-size + 4;
	}

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	margin: auto;
	background: $cf-color-background;
	overflow: hidden;

	opacity: 0;
	will-change: opacity;

	.conversational-form-inner {
		padding: 0px 20px;
		position: absolute;
		width: 100%;
		min-height: 100%;
		max-height: 100%;
		bottom: 0px;
		height: auto;
		overflow: hidden;
		margin-bottom: auto;
		// flow children to bottom
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
		align-items: baseline;

		@media (max-width: $cf-breakpoint-medium) {
			padding: 0px 14px;
		}
	}
	
	.conversational-form--enable-animation & {
		transition: opacity $cf-anim-time * 0.25 $cf-ease-easeOut;
	}

	// test

	// disable user selection for the app feel
	* {
		// user-select none
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		tap-highlight-color: rgba(255, 255, 255, 0);
	}
}

.conversational-form--show {
	opacity: 1;
}

