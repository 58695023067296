@import '../node_modules/conversational-form/src/styles/conversational-form.scss';

cf-chat-response.robot thumb {
  background-image: url('../static/jens_square.jpg') !important;
}

// cf-chat-response.user thumb {
  // background-image: url(https://cdn.jsdelivr.net/gh/space10-community/conversational-form@latest/src/images/human.png) !important;
// }

// .conversational-form {
//   border: none !important;
//   background: inherit !important;

//   &:before {
//     display: none;
//   }
// }
