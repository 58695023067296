.cases {
  @include container();

  opacity: 0;
  will-change: opacity;
  margin-bottom: 4rem;

  .case {
    @include column(6);

    @include respond-below('md') {
      margin-bottom: 2rem;
    }

    @include respond-below('xs') {
      @include column(12);
    }

    video {
      width: calc(100% - 40px);
      max-width: 100%;
      margin-bottom: 1rem;
      border: 20px solid #fefefe;

      @include respond-below('md') {
        width: calc(100% - 20px);
        border: 10px solid #fefefe;
      }
    }

    p {
      font-size: 1rem;
      line-height: 120%;
      margin-bottom: 1rem;

      @include respond-below('sm') {
        font-size: 0.88rem;
        line-height: 21px;
      }

      strong {
        font-weight: bold;
      }

      &.label {
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }

    a {
      color: #222;
      text-decoration: none;
      font-weight: bold;
      background: #fefefe;
      padding-left: 2px;
      padding-right: 2px;
      margin-left: 2px;
      margin-right: 2px;
    }

    &--portrait {
      video {
        min-height: 600px;
      }
    }
  }
}
