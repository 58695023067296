@import './variables';
@import './media-queries';
@import './css-grid';
// @import './headline';
@import './cases';
// @import './_buttonRollUpAnimation';
@import './cf-theme';

@font-face {
  font-family: 'Interface';
  font-style:  normal;
  font-weight: 400;
  src: url("../static/fonts/Inter-Regular.woff2") format("woff2"),
       url("../static/fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Interface';
  font-style:  normal;
  font-weight: 700;
  src: url("../static/fonts/Inter-Bold.woff2") format("woff2"),
       url("../static/fonts/Inter-Bold.woff") format("woff");
}


html,
body {
    background-color: $color-background;
    color: #FEFFFF;
    font-family: $font-family;
    font-size: 16px;
    line-height: 18px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.conversational-form,
.conversational-form textarea,
.conversational-form input {
  font-family: $font-family !important;
}

.wrapper {
  opacity: 0;
  will-change: opacity;
}

.row {
  @include container();
}

header {
  opacity: 0;
  will-change: opacity;

  a {
    display: inline-block;
    text-decoration: none;
  }
}


/* h1 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 62px;
  line-height: 104px;
  letter-spacing: -0.79px;
  text-align: center;
  color: $color-primary;

  margin-top: 11rem;
  margin-bottom: 9rem;

  @include respond-below('md') {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  @include respond-below('sm') {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 42px;
    line-height: 84px;
  }

  opacity: 0;
  will-change: opacity;

  > span {
    position: relative;
    top: 6px;
  }
} */


.form {
  height: 0;
  visibility: hidden;
}

.cf {
  opacity: 0;
  will-change: opacity;
  height: 660px;
  max-width: 720px;
  margin: 3rem auto;

  @include respond-below('md') {
    width: 70%;
  }

  @include respond-below('sm') {
    width: 92%;
  }

  .conversational-form {
    border: 1px solid #2F2F2F;
    border-radius: 13px;
  }
}

ul {
   list-style-type: disc;
   margin-bottom: 1rem;
   margin-left: 1.2rem;
}


.footer {
  text-align: center;
  font-size: 0.6rem;
  margin-bottom: 1rem;
  text-transform: uppercase;

  a {
    color: $color-primary;
    text-decoration: none;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      color: $color-background;
      background: $color-primary;
    }
  }
}
