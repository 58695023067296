.cf-progressBar {
	background-color: lighten($cf-color-secondary, 20%);
	width: 100%;
	height: relativeValue(2px);
	z-index: 999;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity .5s ease-in-out;
	will-change: opacity;

	&.show {
		opacity: 1;
	}

	.bar {
		width: 0;
		height: 100%;
		background-color: $cf-color-primary;
		transition: width .3s ease-in-out;
		will-change: width;
	}
}
