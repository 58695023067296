@import "../_cf-variables";

$introDelay: 0.4s;

cf-input {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	margin-top: 0px;
	flex: 0 0 auto;
	background: $cf-color-background;

	@media (max-width: $cf-breakpoint-medium) {
		margin-bottom: 14px;
	}

	.inputWrapper {
		position: relative;
	}

	&.animate-in {
		input, textarea {
			transition-delay: 0s, 0s, 0s, $introDelay, $introDelay;
			opacity: 1;
			transform: translateY(0px) rotateX(0deg);
		}
		
		cf-input-button.cf-input-button {
			transition-delay: 0s, 0s $introDelay + 0.35;
			transform: scale(1, 1);
		}
	}

	input, textarea {
		resize: none;
		overflow: hidden;
		padding: calc((#{$cf-input-field-height} - 4px - #{$cf-input-font-size})/2) #{$cf-input-field-height} calc((#{$cf-input-field-height} - 4px - #{$cf-input-font-size})/2) calc((#{$cf-input-field-height} - 2px - #{$cf-input-font-size})/2);
	}
	
	input, textarea {
		// resets..
		&:-webkit-autofill {
			background-color: white;
		}

		outline: none;
		-webkit-appearance: none;

		// make sure no one can overwrite user-select as it has unintended behavior in safari..
		-webkit-touch-callout: auto !important;
		-webkit-user-select: auto !important;
		-khtml-user-select: auto !important;
		-moz-user-select: auto !important;
		-ms-user-select: auto !important;
		user-select: auto !important;

		&:focus, &:active {
			box-shadow: 0px 0px 6px rgba(0,0,0,0.3);
		}
		
		border-radius: $cf-input-field-border-radius;
		border: none;
		-webkit-appearance: none;
		position: relative;
		z-index: 2;
		display: block;

		// styling
		font-family: $cf-font-family;
		font-size: $cf-input-font-size;

		// @media (max-width: $cf-breakpoint-medium) {
		// 	font-size: $cf-input-font-size-mobile;
		// }

		width: 100%;
		height: $cf-input-field-height;
		min-height: $cf-input-field-height;
		color: $cf-color-input-text;
		will-change: box-shadow, background, color, transform, opacity;
		background: white;
		box-shadow: 0px 0px 1px rgba(0,0,0,0.35);
		opacity: 0;
		transform: translateY(40px) rotateX(-40deg);

		.conversational-form--enable-animation & {
			transition: box-shadow $cf-anim-time $cf-ease-easeOut, background $cf-anim-time $cf-ease-easeOut, color $cf-anim-time $cf-ease-easeOut, transform $cf-anim-time * 0.5 $cf-ease-easeOutBack 0s, opacity $cf-anim-time * 0.5 $cf-ease-easeOut 0s;
		}

		&:hover {
			&::-webkit-input-placeholder { 
				color: darken($cf-color-chat-response-default-text, 20%);
			}
			&::-moz-input-placeholder {
				color: darken($cf-color-chat-response-default-text, 20%);
			}
			&:-moz-input-placeholder {
				color: darken($cf-color-chat-response-default-text, 20%);
			}
			&:-ms-input-placeholder {
				color: darken($cf-color-chat-response-default-text, 20%);
			}
		}
		&::-webkit-input-placeholder {
			color: currentColor;
		}
		&::-moz-input-placeholder {
			color: currentColor;
		}
		&:-moz-input-placeholder {
			color: currentColor;
		}
		&:-ms-input-placeholder {
			color: currentColor;
		}

		transition: all $cf-anim-time/2.4 !important;
		
		&[no-animations] {
			transition: none !important;
		}

		&[rows='1'] {
			min-height: $cf-input-field-height !important;
		}
		
		&:not([rows="1"]) {
			transition-delay: $cf-anim-time * 1.2 !important;
		}
		

		&[rows="2"] {
			min-height: $cf-input-field-height + (($cf-input-font-size+2) * 1) !important;
		}

		&[rows="3"] {
			min-height: $cf-input-field-height + (($cf-input-font-size+2) * 2) !important;
		}

		&[rows="4"] {
			min-height: $cf-input-field-height + (($cf-input-font-size+2) * 3) !important;
		}

		&[rows="5"] {
			min-height: $cf-input-field-height + (($cf-input-font-size+2) * 4) !important;
		}

		&[rows="6"] {
			min-height: $cf-input-field-height + (($cf-input-font-size+2) * 5) !important;
		}
	}
	
	// hide input
	&.hide-input {
		input, textarea {
			display: none;
		}

		cf-input-button {
			display: none;
		}
	}
	// --------------------

	&[disabled="disabled"] {
		pointer-events: none;

		input, textarea {
			background: rgba($cf-color-chat-response-default-bg, .5);
		}

		.cf-input-button {
			background: rgba($cf-color-chat-response-default-bg, .75);
		}
	}
	
	cf-icon-audio-eq {
		display: block;
		border: 0px solid grey;
		border-color: $cf-color-chat-response-user-bg;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		z-index: 0;
		position: absolute;
		top: 0px;
		left: 0px;
	}
	
	&[error] {
		pointer-events: none;

		input, textarea {
			background: $cf-input-color-error-bg;
			color: $cf-input-color-error-text;
		}
		
		.cf-input-button {
			background: $cf-input-color-error-bg;

			svg polygon {
				fill: $cf-input-color-error-text;
			}
		}
	}
	
	&[message] {
		input, textarea {
			background: $cf-color-chat-response-user-bg;
			color: $cf-color-chat-response-user-text;
		}

		.cf-input-button {
			background: $cf-color-chat-response-user-bg;

			svg polygon {
				fill: $cf-color-chat-response-user-text;
			}
		}
	}
	
	&[tag-type="file"] cf-input-button.cf-input-button.loading {
		&:after {
			content: "";
			display: block;
			position: relative;
			left: calc(50% - (#{$cf-input-field-height} / 4));
			top: calc(50% - (#{$cf-input-field-height} / 4));
			
			width: $cf-input-field-height / 2;
			height: $cf-input-field-height / 2;
			
			border: 1px solid rgba(#b9bcbe, 50%);
			border-radius: 50%;
			border-left: 0;
			border-top: 0;
			animation: cf-spin 1s linear infinite;
			will-change: opacity;
			.conversational-form--enable-animation & {
				transition: opacity $cf-anim-time * 0.5 $cf-ease-easeOut;
			}
		}
	}
	
	&[tag-type="file"] cf-input-button.cf-input-button {
		div.cf-icon-attachment {
			transform: rotateX(0deg);
			opacity: 1;
		}
			
		div.cf-icon-progress {
			transform: rotateX(-90deg);
			opacity: 0;
		}
	}
}

cf-input-button.cf-input-button {
	width: $cf-input-field-height;
	height: 100%;
	// height: calc(100% - 10px);
	background: $cf-color-primary;
	position: absolute;
	overflow: hidden;
	right: 0;
	bottom: 0;
	z-index: 3;
	cursor: pointer;
	border-radius: 0;
	will-change: background, border;
	transform-origin: 50% 50%;
	transform: scale(0, 0);
	backface-visibility: hidden;
	border: none;

	.conversational-form--enable-animation & {
		transition: background $cf-anim-time * 0.5 $cf-ease-easeOut, border $cf-anim-time * 0.5 $cf-ease-easeOut, transform $cf-anim-time * 0.5 $cf-ease-easeOutBack 0s;
	}

	// mic behavior
	.cf-input-icons.cf-microphone {
		transform: scale(0, 0);
		pointer-events: none;
	}
	
	&.microphone-interface {
		.cf-input-icons {
			transform-origin: 50% 50%;
			transform: scale(1, 1) rotateX(0deg) translateZ(0px);
			transition: transform $cf-anim-time * 0.5 $cf-ease-easeOut;
		}

		.cf-input-icons:not(.cf-microphone){
			pointer-events: none;
			transform: scale(0.25, 0.25) rotateX(90deg) translateZ(0px);
		}
		
		.cf-input-icons.cf-microphone {
			pointer-events: auto;
			transform: scale(1, 1) rotateX(0deg) translateZ(0px);
		}
		
		&.loading:not(.typing) {
			pointer-events: none;
			.cf-microphone {
				div.cf-icon-audio {
					opacity: 1;
				}
			}
		}
		// when user is typing
		&.typing {
			.cf-input-icons:not(.cf-microphone){
				pointer-events: auto;
				transform: scale(1, 1) rotateX(0deg) translateZ(0px);
			}
			.cf-input-icons.cf-microphone {
				transform: scale(0.25, 0.25) rotateX(90deg) translateZ(0px);
				pointer-events: none;
			}
		}
	}		
	// ------------------------------------------

	.cf-input-icons {
		position: absolute;
		z-index: 1;
		pointer-events: none;
		width: 100%;
		height: 100%;

		> div {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			transform-origin: 50% 50%;
			will-change: transform, opacity;
			.conversational-form--enable-animation & {
				transition: transform $cf-anim-time * 0.5 $cf-ease-easeOut, opacity $cf-anim-time * 0.5 $cf-ease-easeOut;
			}
		}
	}
	
	div.cf-icon-attachment {
		transform: rotateX(90deg);
		opacity: 0;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='12px' height='16px' viewBox='0 0 12 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-573.000000, -605.000000%29' stroke='"+ encodecolor($cf-input-icon-color) + "'%3E%3Cg transform='translate%2884.000000, 136.000000%29'%3E%3Cg transform='translate%281.000000, 456.000000%29'%3E%3Cpath d='M499,23.1092437 L499,18.907563 C499,16.2016807 496.756849,14 494,14 C491.243151,14 489,16.2016807 489,18.907563 L489,24.5042017 C489,26.4369748 490.592466,28 492.561644,28 C494.530822,28 496.123288,26.4369748 496.123288,24.5042017 L496.123288,18.907563 C496.140411,17.7478992 495.181507,16.8067227 494,16.8067227 C492.818493,16.8067227 491.859589,17.7478992 491.859589,18.907563 L491.859589,23.1092437'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		background-size: relativeValue(12px) relativeValue(16px);
	}

	div.cf-icon-progress {
		transform: rotateX(0deg);
		opacity: 1;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='"+ encodecolor($cf-input-icon-color) + "'%3E%3Cpolygon transform='translate%2815.500000, 15.500000%29 rotate%2890.000000%29 translate%28-15.500000, -15.500000%29 ' points='22 14.6875 12.111875 14.6875 16.6496875 10.1496875 15.5 9 9 15.5 15.5 22 16.6496875 20.8503125 12.111875 16.3125 22 16.3125'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		background-size: relativeValue(30px) relativeValue(30px);
	}
	
	div.cf-icon-audio {
		transform: rotateX(0deg);
		opacity: 1;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 16.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0%29 --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='512px' fill='" + encodecolor($cf-input-icon-color) + "' viewBox='0 0 512 512' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M256,320c37.712,0,68.571-30.924,68.571-68.714V100.714C324.571,62.924,293.712,32,256,32s-68.571,30.924-68.571,68.714 v150.572C187.429,289.076,218.288,320,256,320z M377.139,244.548c0,68.714-58.282,116.815-121.139,116.815 s-121.139-48.102-121.139-116.815H96c0,77.873,61.719,143.153,137.144,153.465V480h45.713v-81.987 C354.281,386.561,416,322.421,416,244.548H377.139z'/%3E%3C/g%3E%3C/svg%3E ");
		background-size: relativeValue(26px) relativeValue(26px);
	}

	&:active {
		opacity: 0.2;
	}
	
	&:hover, &:focus {
		background: $cf-color-chat-response-user-bg;
		border: 0;
		&:after {
			opacity: 0;
		}

		@media (min-width: $cf-breakpoint-medium) {
			.cf-icon-progress {
				animation: cf-arrow-up $cf-anim-time $cf-ease-easeInOut;
			}
		}
	}
}
/**
* Show submit button for groups when input is hidden
*/

cf-input[tag-type="file"],
cf-input[tag-type="group"],
cf-input[tag-type="select"] {
	cf-input-button.cf-input-button {
		height: $cf-input-field-height;
	}
}

cf-input[tag-type="file"].hide-input,
cf-input[tag-type="group"].hide-input,
cf-input[tag-type="select"].hide-input {
	cf-input-button {
		display: block;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
	cf-input-control-elements {
		margin-bottom: $cf-input-field-height;
	}
}

cf-input-control-elements {
	// border: 1px solid yellow;
	// margin-bottom: 10px;
}

cf-input[error=""].hide-input {
	cf-input-button {
		.cf-icon-progress {
			filter: saturate(0);
			animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
		}
	}
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%, 80% {
		transform: translate3d(1px, 0, 0);
	}
	30%, 50%, 70% {
		transform: translate3d(-2px, 0, 0);
	}
	40%, 60% {
		transform: translate3d(2px, 0, 0);
	}
}
// EOF - Show submit button for groups when input is hidden


@keyframes cf-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes cf-arrow-up {
	0% {
		transform: translateY(0px);
	}
	40% {
		transform: translateY(-40px);
	}
	40.001% {
		transform: translateY(40px);
	}
	80% {
		transform: translateY(-5px);
	}
	90% {
		transform: translateY(2px);
	}
	100% {
		transform: translateY(0px);	
	}
}